import React, {HTMLProps} from 'react'

import {Theme} from 'layout/theme'
import css from './contentsidebar.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Contentsidebar: React.FC<HTMLProps<HTMLDivElement>> = ({
	children,
	...props
}) => (
	<div className={styles.contentsidebar.mergeProps(props)()}>
		<Theme.Container>{children}</Theme.Container>
	</div>
)

export const ContentsidebarContent: React.FC<HTMLProps<HTMLDivElement>> = ({
	children,
	...props
}) => <div className={styles.content.mergeProps(props)()}>{children}</div>

export const ContentsidebarSidebar: React.FC<{children: React.ReactNode}> = ({
	children
}) => <div className={styles.sidebar()}>{children}</div>
