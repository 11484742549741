import {DPContactpersonTerm, DPDate, DPTagTerm, DPWysiwyg} from 'types/DPTypes'
import {ImageType, WysiwygType} from 'types/basictypes'
import React, {Suspense, useState} from 'react'

import AnimateHeight from 'react-animate-height'
import {Icon} from 'assets/icon'
import {Image} from 'layout/partials/image'
import {Link} from 'util/link'
import {Theme} from 'layout/theme'
import css from './sidebar.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useDpDateToPrettyDate} from 'util/dates'
import {useLinks} from 'util/links'
import {useQueryString} from 'util/createQueryString'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export type SidebarType = {
	authors?: Array<SidebarAuthorType>
	contactpersons?: Array<SidebarContactpersonsType>
	tags?: Array<string>
	date?: string
	more?: WysiwygType
}

export type SidebarAuthorType = {
	image?: ImageType
	name: string
	function?: string
	description?: WysiwygType
}

export type SidebarContactpersonsType = {
	name: string
	function?: string
	mobile?: string
	phone?: string
	email?: string
}

export type SidebarTagType = {
	name?: string
}

export const Sidebar: React.FC<{
	authors?: Array<SidebarAuthorType>
	contactpersons?: Array<DPContactpersonTerm>
	tags: Array<DPTagTerm>
	date?: DPDate
	more?: DPWysiwyg
}> = (data) => {
	const {authors, contactpersons, tags, date, more} = data

	return (
		<div className={styles.sidebar()}>
			<SidebarAuthors authors={authors} />
			<SidebarContactpersons contactpersons={contactpersons} />
			<SidebarMore more={more} />
			<Suspense>
				<SidebarTags tags={tags} />
			</Suspense>
			<SidebarDate date={date} />
		</div>
	)
}

export const SidebarAuthors: React.FC<{
	authors?: Array<SidebarAuthorType>
}> = (data) => {
	const {authors} = data
	if (!authors || authors.length === 0) return null

	return (
		<div className={styles.authors()}>
			{authors.map((author, i) => (
				<SidebarAuthor author={author} key={i} />
			))}
		</div>
	)
}

export const SidebarAuthor: React.FC<{
	author?: SidebarAuthorType
}> = (data) => {
	const {author} = data
	const [isOpen, setState] = useState(true)
	if (!author) return null

	return (
		<div className={styles.authors.item()}>
			{author.image?.src && (
				<div className={styles.authors.item.image()}>
					<Image
						{...author.image}
						sizes="(max-width: 460px) 100vw, (max-width: 768px) 460px, 309px"
					/>
				</div>
			)}
			<div className={styles.authors.item.top()}>
				<div className={styles.authors.item.top.info()}>
					<div className={styles.authors.item.pname()}>{author.name}</div>
					{author.function && (
						<div className={styles.authors.item.function()}>
							{author.function}
						</div>
					)}
				</div>
				<a
					className={styles.authors.item.toggle.is({open: isOpen})()}
					onClick={() => setState(!isOpen)}
				>
					<span className={styles.authors.item.toggle.icon()}>
						<Icon icon="ChevronDown" />
					</span>
				</a>
			</div>

			{author.description && (
				<div className={styles.authors.item.description()}>
					<AnimateHeight height={isOpen ? 'auto' : 0} animateOpacity={true}>
						<Theme.Wysiwyg>{author.description}</Theme.Wysiwyg>
					</AnimateHeight>
				</div>
			)}
		</div>
	)
}

export const SidebarContactpersons: React.FC<{
	contactpersons?: Array<DPContactpersonTerm>
}> = (data) => {
	const t = useTranslation()
	const {contactpersons} = data
	if (!contactpersons || contactpersons.length === 0) return null

	return (
		<div className={styles.contactpersons()}>
			{contactpersons.map((person, i) => (
				<div key={i} className={styles.contactpersons.item()}>
					<div className={styles.contactpersons.item.pname()}>
						{person.name}
					</div>
					{person.contact_function && (
						<div className={styles.contactpersons.item.function()}>
							{person.contact_function}
						</div>
					)}
					<div className={styles.contactpersons.item.contact()}>
						{person.contact_mobile_number && (
							<div
								className={styles.contactpersons.item.contact.icon.mod(
									'mobile'
								)()}
							>
								<a
									href={`tel:${person.contact_mobile_number}`}
									aria-label={t.sidebar.mobile_arialabel}
								>
									<Icon icon="Mobile" />
								</a>
							</div>
						)}
						{person.contact_phone_number && (
							<div
								className={styles.contactpersons.item.contact.icon.mod(
									'phone'
								)()}
							>
								<a
									href={`tel:${person.contact_phone_number}`}
									aria-label={t.sidebar.phone_arialabel}
								>
									<Icon icon="Phone" />
								</a>
							</div>
						)}
						{person.contact_email && (
							<div
								className={styles.contactpersons.item.contact.icon.mod(
									'email'
								)()}
							>
								<a
									href={`mailto:${person.contact_email}`}
									aria-label={t.sidebar.email_arialabel}
								>
									<Icon icon="Envelope" />
								</a>
							</div>
						)}
					</div>
				</div>
			))}
		</div>
	)
}

export const SidebarMore: React.FC<{
	more?: WysiwygType
}> = (data) => {
	const {more} = data
	if (!more) return null

	return (
		<div className={styles.more()}>
			<Theme.Wysiwyg>{more}</Theme.Wysiwyg>
		</div>
	)
}

const SidebarTags: React.FC<{
	tags: Array<DPTagTerm>
}> = (data) => {
	const t = useTranslation()
	const {readingroom} = useLinks()
	const createQueryString = useQueryString()

	const {tags} = data
	if (!tags || tags.length === 0) return null

	return (
		<div className={styles.tags()}>
			<p className={styles.tags.label()}>{t.sidebar.tags}:</p>
			<div className={styles.tags.items()}>
				{tags.map((tag, i) => (
					<div className={styles.tags.items.item()} key={i}>
						<Link
							href={readingroom.url + '?' + createQueryString('tags', tag.name)}
							className={styles.tags.items.item.tag()}
						>
							{tag.name}
						</Link>
					</div>
				))}
			</div>
		</div>
	)
}

export const SidebarDate: React.FC<{date?: string}> = (data) => {
	const {date} = data
	const t = useTranslation()
	const {printDate} = useDpDateToPrettyDate()

	if (!date) return null

	return (
		<div className={styles.date()}>
			<p className={styles.date.label()}>{t.sidebar.date}:</p>
			<p>{printDate(date)}</p>
		</div>
	)
}
