export const countWords = blocks => {
	if (!blocks || blocks.length === 0) return 0
	let wordcount = 0

	blocks.forEach(block => {
		if (block.type === 'textblock' && block.text) {
			const text = block.text.replace(/<[^>]*>?/gm, '')
			wordcount += text.split(' ').length
		}
	})

	return wordcount
}
